import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { ChakraProvider, Box, theme } from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import ScrollToTop from './components/views/ScrollToTop';
import Footer from './components/views/Footer';
import Layout from './components/views/Layout';
import Home from './pages/Home.js';
import NoPage from './pages/misc/NoPage.js';
import About from './pages/misc/About';
import Work from './pages/Work.js';
import Blog from './pages/Blog.js';
import NavBreadCrumb from './components/views/NavBreadCrumb.js';

function AppLocation() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="work" element={<Work />} />
          <Route path="about" element={<About />} />
          <Route path="blog" element={<Blog />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>

      <Box
        style={{
          position: 'absolute',
          top: '0%',
          right: '0%',
        }}
      >
        <ColorModeSwitcher justifySelf="flex-end" />
      </Box>

      <ScrollToTop />

      <NavBreadCrumb />

      <Footer />

      <style>
        {`
        @import url('https://fonts.googleapis.com/css?family=Lobster');
      `}
      </style>
    </ChakraProvider>
  );
}

function App() {
  return (
    <Router>
      <AppLocation />
    </Router>
  );
}

export default App;
