import { Link as ReactLink } from 'react-router-dom';
import {
  Link,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  IconButton,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

function NavMenu() {
  const _style = {
    position: 'absolute',
    top: '0%',
    left: '0%',
  };
  return (
    <div style={_style}>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon w={7} h={7} />}
          variant="outline"
        ></MenuButton>
        <MenuList>
          <MenuItem>
            <Link as={ReactLink} to="/">
              Home
            </Link>
          </MenuItem>
          {/* <MenuItem>
            <Link as={ReactLink} to="/work">
              Work
            </Link>
          </MenuItem> */}
          <MenuItem>
            <Link as={ReactLink} to="/about">
              About
            </Link>
          </MenuItem>
          <MenuItem>
            <Link as={ReactLink} to="/blog">
              Blog
            </Link>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
}

export default NavMenu;
