import { Outlet } from 'react-router-dom';
import NavMenu from './NavMenu';

const Layout = ({ isMagicflowerIdRoute }) => {
  if (isMagicflowerIdRoute) {
    return (
      <>
        <Outlet />
      </>
    );
  } else {
    return (
      <>
        <NavMenu isMagicflowerIdRoute={isMagicflowerIdRoute} />
        <Outlet />
      </>
    );
  }
};

export default Layout;
