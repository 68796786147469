import { Link as ReactLink } from 'react-router-dom';
import {
  Icon,
  Text,
  Link,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  VStack,
} from '@chakra-ui/react';
import { GiButterfly } from 'react-icons/gi';

const NavBreadCrumb = () => {
  return (
    <VStack mt={6}>
      <Breadcrumb
        separator={<Icon as={GiButterfly} w={6} h={6} color="green.300" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/">
              <Text as="i" fontSize="sm">
                Home
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/about">
              <Text as="i" fontSize="sm">
                About
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </VStack>
  );
};

export default NavBreadCrumb;
