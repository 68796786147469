import React from 'react';
import '../styles/Fonts.css';
import { Link as ReactLink } from 'react-router-dom';
import { Text, VStack, Link } from '@chakra-ui/react';
import LotusFlower from '../resources/images/lotus/lotus.png';
import RenderWorkInfo from '../components/views/RenderWorkInfo';

const Home = () => {
  const RenderLotusImg = () => {
    return (
      <div style={{ position: 'relative', textAlign: 'center' }}>
        <img
          src={LotusFlower}
          alt="Lotus Flower"
          style={{ width: '100%', height: 'auto' }}
        />
        <div
          style={{
            position: 'absolute',
            top: '95%',
            right: '28px',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Link as={ReactLink} to="/about">
            <Text
              fontSize="3xl"
              color="green.200"
              style={{ fontFamily: 'Lobster' }}
            >
              About
            </Text>
          </Link>
        </div>

        <div
          style={{
            position: 'absolute',
            top: '38%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <VStack spacing={4}>
            <Text
              fontSize={['xl', '3xl', '4xl', '7xl']}
              color="green.200"//"whiteAlpha.850" 
              style={{ fontFamily: 'Lobster' }}
            >
              Dream Lotus Studio
            </Text>
            <Text
              fontSize={['md', 'lg', 'xl', '2xl']}
              color="green.200"
              style={{ fontFamily: 'Lobster' }}
            >
              Innovating Technology and Digital Art
            </Text>
          </VStack>
        </div>
      </div>
    );
  };

  return (
    <>
      <RenderLotusImg />
      <RenderWorkInfo />
    </>
  );
};

export default Home;
