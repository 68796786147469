import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { Link, Icon } from '@chakra-ui/react';
import { RiHomeHeartLine } from 'react-icons/ri';
const HomeIcon = () => {
  return (
    <Link as={ReactLink} to="/">
      <Icon as={RiHomeHeartLine} w={10} h={10} color="green.300" />
    </Link>
  );
};

export default HomeIcon;
