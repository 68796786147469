import {
  Text,
  VStack,
  Box,
  Link,
  Container,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import Butterfly from '../../resources/images/butterfly.gif';
import DigitalArt1 from '../../resources/images/digitalart1.gif';
import DigitalArt2 from '../../resources/images/digitalart2.gif';
import DigitalArt3 from '../../resources/images/digitalart3.gif';
import DigitalArt4 from '../../resources/images/digitalart4.gif';
import DigitalArt5 from '../../resources/images/digitalart5.gif';
import DigitalArt6 from '../../resources/images/digitalart6.gif';
import { ExternalLinkIcon } from '@chakra-ui/icons'
const RenderWorkInfo = () => {
  const RenderSeletedApp1 = () => {
    return (
      <div
        style={{
          backgroundImage:
            'linear-gradient( 109.6deg,  rgba(20,30,48,1) 11.2%, rgba(36,59,85,1) 91.1% )',
        }}
      >
        <Container>
          <VStack spacing={6}>
            <Box>
              <Text
                color="whiteAlpha.900"
                fontSize={58}
                style={{ fontFamily: 'Lobster' }}
              >
                Selected Web3 App
              </Text>
            </Box>
            <Box>
              <Link href="https://magicnftvalley.com/" isExternal>
                <Text
                  color="green.200"
                  fontSize="4xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  Magic NFT Valley <ExternalLinkIcon mx='2px' />
                </Text>
              </Link>
            </Box>
            <Box>
              <Text fontSize="2xl" color="whiteAlpha.900">
                <Link href="https://magicnftvalley.com/" isExternal>
                  Magic NFT Valley
                </Link>{' '}
                is a fully decentralized hub for NFT sales and auctions,
                specializing in Web3 gaming NFTs. It offers seamless integration
                and a dynamic marketplace and auction house for game developers,
                artists, and players.
              </Text>
            </Box>
            <Box mb={3}>
              <img
                src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1719625815/magicnftvalley_budken.gif"
                alt="Magic NFT Valley"
              />
            </Box>
          </VStack>
        </Container>
      </div>
    );
  };
  const RenderSeletedApp2 = () => {
    return (
      <div
        style={{
          backgroundImage:
            'linear-gradient( 109.6deg,  rgba(5,84,94,1) 16%, #bbb 91.1% )',
        }}
      >
        <Container>
          <VStack spacing={6}>
            <Box>
              <Text
                color="whiteAlpha.900"
                fontSize={58}
                style={{ fontFamily: 'Lobster' }}
              >
                Selected Web3 App
              </Text>
            </Box>
            <Box>
              <Link href="https://cozygames.xyz/" isExternal>
                <Text
                  color="green.200"
                  fontSize="4xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  Cozy Games XYZ  <ExternalLinkIcon mx='2px' />
                </Text>
              </Link>
            </Box>
            <Box>
              <Text fontSize="2xl" color="whiteAlpha.900">
                <Link href="https://cozygames.xyz/" isExternal>
                  Cozy Games XYZ
                </Link>{' '}
                is an arcade dedicated to cozy gaming, featuring a
                blend of cozy blockchain games and traditional favorites. Its
                mission is to provide a tranquil escape from the hustle and
                bustle of everyday life.
              </Text>
            </Box>
            <Box mb={3}>
              <img
                src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1719865400/cat_ksqfrw.gif"
                alt="Cat Playing with a Butterfly"
              />
            </Box>
          </VStack>
        </Container>
      </div>
    );
  };
  return (
    <>
      <div
        style={{
          backgroundImage:
            'radial-gradient( circle farthest-corner at 12.3% 19.3%,  rgba(85,88,218,1) 0%, rgba(95,209,249,1) 100.2% )',
        }}
      >
        <Container>
          <VStack spacing={6}>
            <Box>
              <Text
                color="whiteAlpha.900"
                fontSize={58}
                style={{ fontFamily: 'Lobster' }}
              >
                Dedication & Creation
              </Text>
            </Box>
            <Box>
              <Text fontSize="2xl" color="whiteAlpha.900">
                Dream Lotus Studio is dedicated to transforming the digital
                space through innovative technology and captivating digital art.
              </Text>
            </Box>
            <Box mb={3}>
              <img src={Butterfly} alt="Butterflies" />
            </Box>
          </VStack>
        </Container>
      </div>
      <RenderSeletedApp1 />

      <RenderSeletedApp2 />

      <div
        style={{
          backgroundImage:
            'linear-gradient( 102.1deg,  rgba(96,221,142,1) 8.7%, rgba(24,138,141,1) 88.1% )',
        }}
      >
        <Container>
          <VStack spacing={6}>
            <Box>
              <Text
                color="whiteAlpha.900"
                fontSize={58}
                style={{ fontFamily: 'Lobster' }}
              >
                Selected Web3 Game
              </Text>
            </Box>
            <Box>
              <Link href="https://cozygames.xyz/magicflowers" isExternal>
                <Text
                  color="purple.200"
                  fontSize="4xl"
                  style={{ fontFamily: 'Lobster' }}
                >
                  Magic Flowers <ExternalLinkIcon mx='2px' />
                </Text>
              </Link>
            </Box>
            <Box>
              <Text fontSize="2xl" color="whiteAlpha.900">
                <Link href="https://magicnftvalley.com/magicflowers" isExternal>
                  {' '}
                  Magic Flowers{' '}
                </Link>
                NFTs are digital art that takes on a life of its own. Experience
                dynamic NFTs in Magic Flowers—digital collectibles that evolve
                like living entities, each with unique memories. Enjoy the
                serene ambiance of cozy gaming while witnessing their enchanting
                metamorphosis.
              </Text>
            </Box>
            <Box mb={3}>
              <img
                src="https://res.cloudinary.com/df9jvvwyw/image/upload/v1718370825/cat1_edquhz.gif"
                alt="magic flowers and cat"
              />
            </Box>
          </VStack>
        </Container>
      </div>

      <div
        style={{
          backgroundImage:
            'radial-gradient( circle farthest-corner at 10% 20%,  rgba(37,145,251,0.98) 0.1%, rgba(0,7,128,1) 99.8% )',
        }}
      >
        <VStack spacing={6}>
          <Box>
            <Text
              color="whiteAlpha.900"
              fontSize={58}
              style={{ fontFamily: 'Lobster' }}
            >
              Selected Digital Art
            </Text>
          </Box>

          <Wrap>
            <WrapItem>
              <img src={DigitalArt2} />
            </WrapItem>
            <WrapItem>
              <img src={DigitalArt1} />
            </WrapItem>
            <WrapItem>
              <img src={DigitalArt3} />
            </WrapItem>
          </Wrap>
          <Wrap>
            <WrapItem>
              <img src={DigitalArt4} />
            </WrapItem>
            <WrapItem>
              <img src={DigitalArt5} />
            </WrapItem>
            <WrapItem>
              <img src={DigitalArt6} />
            </WrapItem>
          </Wrap>
        </VStack>
      </div>

      {/* <img src={PaperInfo} alt="" /> */}
      {/* <Link as={ReactLink} to="https://www.kickstarter.com/" isExternal>
          <Button colorScheme="green" variant="outline">
            Join Our Kickstarter
          </Button>
        </Link> */}
      {/* <Icon as={FaStarAndCrescent} w={8} h={8} color="green.500" />

        <Box>
          <MintMagicFlowerPlantButton />
        </Box>
        <Icon as={GiTwirlyFlower} w={8} h={8} color="green.500" />
        <Box>
          <SearchMagicFlowers />
        </Box>

        <Box>
          <Link as={ReactLink} to="/allmymagicflowers">
            <Text
              fontSize="lg"
              color="green.300"
              style={{ fontFamily: 'Lobster' }}
            >
              View All My Magic Flowers
            </Text>
          </Link>
        </Box>

        <HStack spacing={8}>
          <VStack>
            <Icon as={IoMdImages} w={8} h={8} color="green.500" />
            <Box>
              <BackdropFilter
                title="Marketplace"
                gameTokenId={1}
                gameName="Magic Flowers"
                isMarketplace={true}
              />
            </Box>
          </VStack>
          <VStack>
            <Icon as={RiAuctionFill} w={8} h={8} color="green.500" />
            <Box>
              <BackdropFilter
                title="Auction House"
                gameTokenId={process.env.GAME_TOKEN_ID}
                gameName="Magic Flowers"
                isMarketplace={false}
              />
            </Box>{' '}
          </VStack>
        </HStack> */}

      {/* <Box>
          <NavBreadCrumbHome />
        </Box> */}
    </>
  );
};

export default RenderWorkInfo;
