import React from 'react';
import ComingSoon from '../resources/images/coming.png';
import HomeIcon from '../components/views/HomeIcon'

const Blog = () => {
  return (
    <>
     <div style={{ position: 'relative', textAlign: 'center' }}>
      <img
        src={ComingSoon}
        alt="Coming Soon"
        style={{ width: '100%', height: 'auto' }}
      />
          <div
          style={{
            position: 'absolute',
            top: '3%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <HomeIcon />
        </div>
      </div>
    </>
  );
};

export default Blog;
